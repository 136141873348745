<template>
  <el-form class="login-form" status-icon :rules="loginRules" ref="loginForm" :model="loginForm" label-width="0"
    :disabled="processing">
    <el-form-item prop="username">
      <el-input @keyup.enter="handleLogin" v-model="loginForm.username" auto-complete="off" placeholder="请输入账号">
        <template #prefix>
          <i class="icon-yonghu"></i>
        </template>
      </el-input>
    </el-form-item>
    <el-form-item prop="password">
      <el-input @keyup.enter="handleLogin" :type="passwordType" v-model="loginForm.password" auto-complete="off"
        placeholder="请输入密码">
        <template #suffix>
          <i class="el-icon-view el-input__icon" @click="showPassword"></i>
        </template>
        <template #prefix>
          <i class="icon-mima"></i>
        </template>
      </el-input>
    </el-form-item>
    <!-- <el-checkbox v-model="checked">记住账号</el-checkbox> -->
    <el-form-item>
      <el-button icon="el-icon-monitor" type="primary" @click.prevent="handleLogin" class="login-submit"
        style="width: 100%" :loading="processing">登 录</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { isvalidUsername } from "@/utils/validate";
export default {
  name: "userlogin",
  data() {
    const validateUsername = (rule, value, callback) => {
      if (!isvalidUsername(value)) {
        callback(new Error("请输入正确的用户名"));
      } else {
        callback();
      }
    };
    const validateCode = (rule, value, callback) => {
      if (this.code.value !== value) {
        this.loginForm.code = "";
        this.refreshCode();
        callback(new Error("请输入正确的验证码"));
      } else {
        callback();
      }
    };
    return {
      processing: false,
      loginForm: {
        username: "",
        password: "",
      },
      checked: false,
      code: {
        src: "",
        value: "",
        len: 4,
        type: "text",
      },
      loginRules: {
        username: [
          { required: true, trigger: "blur", validator: validateUsername },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, message: "密码长度最少为6位", trigger: "blur" },
        ],
        code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { min: 4, max: 4, message: "验证码长度为4位", trigger: "blur" },
          { required: true, trigger: "blur", validator: validateCode },
        ],
      },
      passwordType: "password",
    };
  },
  computed: {},
  props: {
    logOnHuman: String,
  },
  methods: {
    showPassword() {
      this.passwordType === ""
        ? (this.passwordType = "password")
        : (this.passwordType = "");
    },
    handleLogin() {
      this.$refs.loginForm.validate(async (valid) => {
        if (valid) {
          this.processing = true;
          this.$store
            .dispatch("user/login", {
              code: this.loginForm.username,
              password: this.loginForm.password,
              logOnType: 0,
              logOnHuman: this.logOnHuman,
            })
            .then((res) => {
              this.processing = false;
              this.$router.push(this.$route.query.redirect ? this.$route.query.redirect : { path: "/" });
            })
            .catch((err) => {
              this.$message.error(err.error_description);
              this.processing = false;
            });

          // let user = await logon(
          //   this.loginForm.username,
          //   this.loginForm.password
          // );
          // // console.log(user);
          // if (user != null) {
          //   sessionStorage.setItem("token", JSON.stringify(user));
          //   this.$router.push({ path: "/" });
          // }
        }
      });
    },
  },
};
</script>
<style>
</style>
