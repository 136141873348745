<template>
  <div class="login-container">
    <div class="login-page">
      <div class="logo">
        <div>
          <img :src="require('@/assets/images/Logo.png')" />
        </div>
        <div
          style="
            font-family: 'Helvetica Neue', Helvetica, 'PingFang SC',
              'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', Arial,
              sans-serif;
            font-size: 20pt;
            font-weight: normal;
          "
        >
          {{ title }}
        </div>
      </div>
      <el-tabs v-model="activeName">
        <el-tab-pane label="账号密码" name="user">
          <userLogin :logOnHuman="logOnHuman"></userLogin>
        </el-tab-pane>
        <!-- <el-tab-pane label="验证码登录" name="phone">
          <codeLogin :logOnHuman="logOnHuman"></codeLogin>
        </el-tab-pane> -->
      </el-tabs>
      <div class="btmbtn">
        <!-- <router-link to="/register">用户注册</router-link>
        <el-divider direction="vertical"></el-divider>
        <router-link to="/forgetpassword">忘记密码</router-link> -->

        <p>
          <i class="el-icon-question"></i> 建议使用 WebKit
          核心浏览器，如谷歌(Chrome)，360极速，搜狗等，最佳分辨率 1920 x 1080
        </p>
        <!-- <p  class="ng-binding">京ICP备1xxxxxxx号 | <a href="http://www.beian.miit.gov.cn" target="_blank">工业和信息化部</a></p> -->
        <p class="ng-binding">{{ copyright }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import userLogin from "./userlogin";
// import codeLogin from "./codelogin";
import { mapGetters } from "vuex";
import settings from "@/settings.js";
export default {
  name: "login",
  components: {
    userLogin,
    //codeLogin,
  },
  data() {
    return {
      ruleForm2: {},
      activeName: "user",
      title: settings.title,
      copyright: settings.copyright,
    };
  },
  computed: {
    ...mapGetters(["website"]),
  },
  props: {
    logOnHuman: String,
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/login.scss";
</style>

